import * as React from "react"
import { auth } from "../core/firebase"
import { useCurrentUser } from "../hooks/useCurrentUser"
import { Link, Redirect } from "@reach/router"
import { FullBleed } from "../components/FullBleed"
import { Form } from "../components/Form"
import { Card } from "../components/Card"
import { Grid, GridCell } from "@rmwc/grid"
import { TextField } from "../components/rmwc/TextField"

import "@material/layout-grid/dist/mdc.layout-grid.css"

function Signin() {
  const { currentUser } = useCurrentUser()
  const [email, setemail] = React.useState("")
  const [password, setpassword] = React.useState("")
  const [error, setError] = React.useState("")
  const [processing, setProcessing] = React.useState(false)

  const handleSignin = event => {
    event.preventDefault()
    setProcessing(true)

    auth.signInWithEmailAndPassword(email, password).catch(error => {
      setError(error.message)
      setProcessing(false)
    })
  }

  return (
    <FullBleed>
      {currentUser && <Redirect to="/" noThrow />}

      <Grid>
        <GridCell span={4} />
        <GridCell span={4}>
          <Card title="Signin">
            <Form
              disabled={processing}
              onSubmit={handleSignin}
              submitText="Sign in"
              message={error}
              messageType={"error"}
              active={true}
            >
              <TextField state={[email, setemail]} label="Email address" />
              <TextField
                state={[password, setpassword]}
                label="Password"
                type="password"
              />
            </Form>
            <br />
            <div>
              <Link to="/signup">Don't have an account? Register here.</Link>
            </div>
          </Card>
        </GridCell>
      </Grid>
    </FullBleed>
  )
}

export default Signin
